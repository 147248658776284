import React, { useState, useEffect } from "react";
import axios from "axios";
import GigGrid from "../SellerProfile/GigGrid";
import CenterSpinner from "../Components/CenterSpinner";
import { getApiDomain } from "../App";
import FillWithMargin from "../Components/FillWithMargin";

export default function FeaturedGigs() {
  const [jobs, setJobs] = useState();
  let query = "";

  useEffect(() => {
    async function getJobs() {
      await axios
        .get(getApiDomain() + "/searchJobs", {
          params: {
            query: query,
            limit: 6,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setJobs(response.data.jobs);
          }
        })
        .catch((err) => {
          console.log("there was an error: " + err); // TODO catch error
        });
    }
    getJobs();
  }, [query]);

  return (
    <>
      <h2 className="mb-4">Featured Gigs</h2>
      {jobs ? (
        <GigGrid jobs={jobs} gridLayout={"row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"} />
      ) : (
        <div>
          <CenterSpinner />
        </div>
      )}
    </>
  );
}
