import { useNavigate } from "react-router-dom"; // version 5.2.0
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { redirectToAuth } from "supertokens-auth-react";
import React, { useEffect, useState, useContext } from "react";
import {
  MDBNavbarToggler,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarLink,
  MDBIcon,
  MDBContainer,
  MDBCollapse,
} from "mdb-react-ui-kit";
import bflogo2 from "../images/logo_transparent_cropped.png";
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { getApiDomain } from "../App";
import UserContext from "../Context/UserContext";
let { useIsDesktop } = require("../Hooks/WindowDimensions");

Session.addAxiosInterceptors(axios);

async function logoutClicked() {
  await signOut();
}

function Navigation() {
  const [showBasic, setShowBasic] = useState(false);
  const [userData, setUserData] = useState({
    userName: "",
    profilePic: "",
  });
  const navigate = useNavigate();
  const { setUsername } = useContext(UserContext);
  const isDesktop = useIsDesktop();

  let session = useSessionContext();

  let { doesSessionExist } = session;

  // doesSessionExist will always be true if this is wrapped in `<SessionAuth>`
  if (!doesSessionExist) {
    // TODO
  }

  useEffect(() => {
    async function getUser() {
      try {
        let response = await axios.get(getApiDomain() + "/sessioninfo");
        if (response.data.user != null) {
          setUserData(response.data.user);
          setUsername(response.data.user.userName);
        }
      } catch (error) {
        console.log("Navigation.js there was an error: " + error);
        setUsername("");
      }
    }
    getUser();
  }, [session?.userId, setUsername]);

  async function onSignUp() {
    redirectToAuth({ show: "signup" });
  }

  async function onSignIn() {
    redirectToAuth({ show: "signin" });
  }

  return (
    <>
      <MDBNavbar expand="lg" dark bgColor="dark">
        <MDBContainer
          fluid
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: isDesktop ? "2rem" : "1rem",
            paddingRight: isDesktop ? "2rem" : "1rem",
            maxWidth: "1500px",
          }}
        >
          <MDBNavbarBrand href="/">
            <img
              src={bflogo2}
              alt=""
              loading="lazy"
              className="img-fluid"
              style={{ maxWidth: "200px" }}
            />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowBasic((curr) => !curr)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-end">
              {doesSessionExist && (
                <>
                  <MDBNavbarLink
                    onClick={() => {
                      setShowBasic(false);
                      navigate(`/user/${userData.userName}`);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Profile
                  </MDBNavbarLink>
                  <MDBNavbarLink
                    onClick={() => {
                      setShowBasic(false);
                      navigate("/post");
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Post New Gig
                  </MDBNavbarLink>
                  <MDBNavbarLink
                    onClick={() => {
                      setShowBasic(false);
                      navigate(`/orders`);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    My Orders
                  </MDBNavbarLink>
                  <MDBNavbarLink
                    onClick={() => {
                      setShowBasic(false);
                      navigate(`/settings`);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Settings
                  </MDBNavbarLink>
                  <MDBNavbarLink
                    onClick={async () => {
                      setShowBasic(false);
                      await signOut();
                      navigate("/auth");
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Sign Out
                  </MDBNavbarLink>
                </>
              )}
              {!doesSessionExist && (
                <>
                  <MDBNavbarLink
                    onClick={() => {
                      setShowBasic(false);
                      onSignUp();
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Sign Up
                  </MDBNavbarLink>
                  <MDBNavbarLink
                    onClick={() => {
                      setShowBasic(false);
                      onSignIn();
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Sign In
                  </MDBNavbarLink>
                </>
              )}
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}

export default Navigation;
