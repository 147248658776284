import React from "react";
import HeroSection from "./HeroSection";
import BrowseByCategory from "./ExploreTheMarketplace";
import CallToAction from "./CallToAction";
import FeaturedGigs from "./FeaturedGigs";
import Testimonials from "./Testimonial";

export default function Home() {
  return (
    <div className="fill" style={{ width: "100%" }}>
      <HeroSection />
      <div style={{ marginTop: "2rem", marginLeft: "2rem", marginRight: "2rem" }}>
        <BrowseByCategory />
      </div>
      <div style={{ marginTop: "2rem" }}>
        <CallToAction />
      </div>
      <div style={{ margin: "2rem" }}>
        <FeaturedGigs />
      </div>
    </div>
  );
}
