import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProfileCard from "./ProfileCard";
import AboutCard from "./AboutCard";
import GigGrid from "./GigGrid";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import Reviews from "../Reviews/Reviews";
import ReviewComments from "../Reviews/ReviewComments";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import CenterSpinner from "../Components/CenterSpinner";
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { getApiDomain } from "../App";
import FillWithMargin from "../Components/FillWithMargin";

Session.addAxiosInterceptors(axios);

export default function Profile() {
  let { username } = useParams();
  const isDesktop = useIsDesktop();
  let session = useSessionContext();
  const [userAccountData, setuserAccountData] = useState(null);

  // get current users username
  useEffect(() => {
    async function getUser() {
      await axios
        .get(getApiDomain() + "/userinfo", {
          params: { username: username },
        })
        .then((response) => {
          setuserAccountData(response.data.userAccountData);
        })
        .catch((err) => {
          console.log("there was an error: " + err); // TODO catch error
        });
    }
    // // TODO AARON implement error handling and notify developers
    getUser().catch((err) => {
      console.log("there was an error: " + err);
    });
  }, [username]);

  const gigGridStyle = isDesktop
    ? { marginLeft: "2rem" }
    : {
        marginTop: "2rem",
      };

  const profileDataStyle = isDesktop ? { minWidth: "40%", maxWidth: "40%" } : { minWidth: "100%" };

  let { doesSessionExist, accessTokenPayload } = session;

  // doesSessionExist will always be true if this is wrapped in `<SessionAuth>`
  if (!doesSessionExist) {
    // TODO AARON
  }

  if (userAccountData !== null && userAccountData.userName === "") {
    return (
      <FillWithMargin>
        <p style={{ textAlign: "center" }}> This user does not exist.</p>
      </FillWithMargin>
    );
  }

  if (userAccountData == null) {
    return <CenterSpinner />;
  }

  return (
    <FillWithMargin>
      <div
        style={{
          display: "flex",
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        <div style={profileDataStyle}>
          <ProfileCard
            cardStyle={cardStyle}
            userAccountData={userAccountData}
            iconColStyle={iconColStyle}
          />
          <AboutCard cardStyle={cardStyle} userAccountData={userAccountData} />
        </div>
        <div style={gigGridStyle}>
          <p className="fw-bold fs-3">{userAccountData.userName}'s Gigs</p>
          {userAccountData.gigs.length > 0 ? (
            <GigGrid
              jobs={userAccountData.gigs}
              gridLayout={"row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"}
            />
          ) : (
            <p>{userAccountData.userName} has no active gigs</p>
          )}
          <div style={{ marginTop: "2rem" }}>
            <Reviews userName={userAccountData.userName} />
          </div>
        </div>
      </div>
    </FillWithMargin>
  );
}

const cardStyle = {};

const iconColStyle = { textAlign: "center" };
