import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// images is an array of files
export default function GigImageCarousel({ images }) {
  return (
    <div style={{ marginTop: "2rem", width: "100%" }}>
      <Carousel>
        {images.map((image, index) => {
          return (
            <div key={index}>
              <img src={image} alt="" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
