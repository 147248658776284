import React, { useRef, useState, useEffect } from "react";

import "./Dropdown.css";

const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

export default function MultiSelect({
  placeHolder, // greyed out text displayed when no text is entered at the cursor
  onChange, // udpate outside state with currently selected items
  maxItems = 5, // max number of items allowed to be selected at a given time
  defaultValues, // default values selected [{label: "", value: ""}]
}) {
  const [selectedValue, setSelectedValue] = useState([]);
  const [currText, setCurrText] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (defaultValues) {
      setSelectedValue(defaultValues);
    }
  }, [defaultValues]);

  // for (const value in defaultValues) {
  // }

  const getDisplay = () => {
    return (
      <div className="dropdown-tags">
        {selectedValue.map((option) => (
          <div key={option.value} className="dropdown-tag-item">
            {option.label}
            <span onClick={(e) => onTagRemove(e, option)} className="dropdown-tag-close">
              <CloseIcon />
            </span>
          </div>
        ))}
      </div>
    );
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.value !== option.value);
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValues = removeOption(option);
    setSelectedValue(newValues);
    onChange(newValues);
  };

  function onKeyDown(e) {
    if (e.keyCode === 32) {
      //spacebar
      e.preventDefault();
      if (
        currText !== "" &&
        selectedValue.length < maxItems &&
        !selectedValue.some((item) => item.label === currText && item.value === currText)
      ) {
        const newValues = [...selectedValue, { label: currText, value: currText }];
        setSelectedValue(newValues);
        onChange(newValues);
        setCurrText("");
      }
    }
  }

  return (
    <div className="dropdown-container">
      <div
        ref={inputRef}
        className="dropdown-input2"
        style={{
          padding: "5.28px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          userSelect: "none",
          color: "#4f4f4f",
        }}
      >
        <div
          className="dropdown-selected-value"
          style={{
            display: "flex",
            marginLeft: ".6rem",
          }}
        >
          {getDisplay()}
        </div>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            marginLeft: selectedValue.length > 0 ? "1rem" : "0px",
          }}
        >
          <input
            style={{
              border: "none",
              backgroundColor: "transparent",
              resize: "none",
              outline: "none",
            }}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              setCurrText(e.target.value);
            }}
            placeholder={placeHolder}
            value={currText}
          />
        </div>
      </div>
    </div>
  );
}
