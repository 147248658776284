import React, { useState, useEffect } from "react";
import { MDBCardText, MDBRow, MDBCol, MDBProgress, MDBProgressBar } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { getApiDomain } from "../App";
import axios from "axios";
import ReviewComments from "./ReviewComments";
import ProgressBar from "./ProgressBar";

export default function Reviews({ userName }) {
  const [sellerRating, setSellerRating] = useState(5);
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    async function getReviews() {
      let response = await axios.get(getApiDomain() + "/sellerReviews", {
        params: { username: userName },
      });
      if (response.status === 200) {
        setReviews(response.data.reviews);
        setSellerRating(response.data.rating);
      } else if (response.status === 500) {
        // TODO AARON implement error handling and notify developers
        console.log("there was an error: " + response.data.error);
      }
    }
    // TODO AARON implement error handling and notify developers
    if (userName !== undefined) {
      getReviews().catch((err) => {
        console.log("there was an error: " + err);
      });
    }
  }, [userName]);

  function getPecrcentOfReviewsMatchingRating(rating) {
    // prevent divide by zero
    if (reviews.length === 0) {
      return 0;
    }

    let matchingReviews = reviews.filter((review) => review.stars === rating);
    return (matchingReviews.length / reviews.length) * 100;
  }

  return (
    <>
      <p className="fw-bold fs-3">Reviews</p>
      {reviews.length === 0 ? (
        <p>This seller has no reviews yet.</p>
      ) : (
        <>
          <MDBRow>
            <MDBCol size="auto">
              <p className="fs-5" style={{ fontWeight: "bold" }}>
                {reviews.length} Review{reviews.length === 1 ? "" : "s"}
              </p>
            </MDBCol>
            <MDBCol>
              <MDBRow>
                <MDBCol size="auto">
                  {[...Array(sellerRating)].map((e, i) => {
                    return <FontAwesomeIcon icon={faStar} style={{ color: "orange" }} key={i} />;
                  })}
                </MDBCol>
                <MDBCol style={{ padding: 0 }}>
                  <MDBCardText
                    style={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {sellerRating} stars
                  </MDBCardText>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <ProgressBar progress={getPecrcentOfReviewsMatchingRating(5)} label={<>5&nbsp;Stars</>} />
          <ProgressBar progress={getPecrcentOfReviewsMatchingRating(4)} label={<>4&nbsp;Stars</>} />
          <ProgressBar progress={getPecrcentOfReviewsMatchingRating(3)} label={<>3&nbsp;Stars</>} />
          <ProgressBar progress={getPecrcentOfReviewsMatchingRating(2)} label={<>2&nbsp;Stars</>} />
          <ProgressBar
            progress={getPecrcentOfReviewsMatchingRating(1)}
            label={<>&nbsp;1&nbsp;Star</>}
          />
          <div style={{ marginTop: "2rem" }}>
            <ReviewComments reviews={reviews} />
          </div>
        </>
      )}
    </>
  );
}
