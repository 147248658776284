import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function SearchBar({ style }) {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  function updateSearchQuery(event) {
    setQuery(event.target.value.replace(/ /g, "_"));
  }

  const handleSubmit = (event) => {
    if (query !== "") {
      navigate("/search/" + query);
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [query]);
  return (
    <>
      <div style={{ ...style }}>
        <form className="d-flex input-group w-auto">
          <input
            type="search"
            className="form-control"
            placeholder={`Try "web design"`}
            aria-label="Search"
            onChange={(event) => updateSearchQuery(event)}
          />
          <MDBBtn
            onClick={handleSubmit}
            type="submit"
            style={{ backgroundColor: "orange", color: "white" }}
          >
            <FontAwesomeIcon icon={faSearch} style={{ color: "white" }} />
          </MDBBtn>
        </form>
      </div>
    </>
  );
}
