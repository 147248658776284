import SuperTokens, {
  SuperTokensWrapper,
  getSuperTokensRoutesForReactRouterDom,
} from "supertokens-auth-react";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";
import Navigation from "./Navigation/Navigation";
import Home from "./Home/Home";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Footer from "./Footer/Footer";
// import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./custom.scss";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import FileNotFound from "./FileNotFound/FileNotFound";
import Search from "./Search/Search";
import Profile from "./SellerProfile/Profile";
import GigPage from "./GigPage/GigPage";
import Settings from "./Settings/Settings";
import PostNewGig from "./PostNewGig/PostNewGig";
import Cart from "./Cart/Cart";
import Orders from "./Orders/Orders";
import CompleteRequirements from "./Orders/CompleteRequirements";
import Deliver from "./Orders/Deliver";
import Delivery from "./Orders/Delivery";
import Messages from "./Messages/Messages";
import React, { useState } from "react";
import SessionExpiredPopup from "./SessionExpiredPopup";
import SessionComponent from "./Components/SessionComponent";
import Revision from "./Orders/Revision";
import Revisions from "./Orders/Revisions";
import CreateGig from "./PostNewGig/CreateGig";
import GigAttributes from "./PostNewGig/GigAttributes";
import DescriptionAndFaq from "./PostNewGig/DescriptionAndFaq";
import Requirements from "./PostNewGig/Requirements";
import Gallery from "./PostNewGig/Gallery";
import LeaveReview from "./Orders/LeaveReview";

// fortawesome
// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import TermsOfService from "./TermsOfService/TermsOfService";

export function getApiDomain() {
  if (!process.env.REACT_APP_API_URL) {
    // thow exception
    throw new Error("REACT_APP_API_URL is not defined");
  }
  const apiUrl = process.env.REACT_APP_API_URL;
  return apiUrl;
}

export function getWebsiteDomain() {
  if (!process.env.REACT_APP_WEBSITE_URL) {
    // thow exception
    throw new Error("REACT_APP_WEBSITE_URL is not defined");
  }
  const websiteUrl = process.env.REACT_APP_WEBSITE_URL;
  return websiteUrl;
}

export function getWebsocketUrl() {
  if (!process.env.REACT_APP_WEBSOCKET_URL) {
    // thow exception
    throw new Error("REACT_APP_WEBSOCKET_URL is not defined");
  }
  const webSocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
  return webSocketUrl;
}

console.log("API URL: " + getApiDomain());
console.log("WEBISTE: " + getWebsiteDomain());
console.log("WEBSOCKET: " + getWebsocketUrl());

SuperTokens.init({
  appInfo: {
    appName: "bitcoinerfreelance.com",
    apiDomain: getApiDomain(),
    websiteDomain: getWebsiteDomain(),
  },
  recipeList: [
    EmailVerification.init({
      mode: "REQUIRED",
    }),
    EmailPassword.init({
      getRedirectionURL: async (context) => {
        if (context.action === "SUCCESS") {
          return "/";
        }
        return undefined;
      },
      palette: {
        // background: "#333",
        // error: "#ad2e2e",
        // textTitle: "white",
        // textLabel: "white",
        // textInput: "#a9a9a9",
        // textPrimary: "white",
        // textLink: "#a9a9a9",
        // display: "none",
      },
      style: {
        superTokensBranding: {
          display: "none",
        },
      },
      signInAndUpFeature: {
        signUpForm: {
          formFields: [
            {
              id: "userName",
              label: "User Name",
              placeholder: "enter a unique username",
            },
            // {
            //   id: "access_code",
            //   label: "Access Code",
            //   placeholder: "enter access code",
            // },
          ],
          termsOfServiceLink: `${getWebsiteDomain()}/terms-and-conditions`,
          privacyPolicyLink: `${getWebsiteDomain()}/privacy-policy`,
        },
      },
    }),
    Session.init(),
  ],
});

function App() {
  return (
    <div className="App">
      <SuperTokensWrapper>
        <Router>
          <Navigation />
          <Routes>
            {/* This shows the login UI on "/auth" route */}
            {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"))}
            <Route path="/" element={<Home />} />
            <Route path="search/:query" element={<Search />} />
            <Route
              path="settings"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <Settings />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/post"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <CreateGig />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/post/add-features"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <GigAttributes />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/post/description-and-faq"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <DescriptionAndFaq />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/post/requirements"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <Requirements />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/post/gallery"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <Gallery />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route path="/user/:username" element={<Profile />} />
            <Route path="gig/:gigId" element={<GigPage />} />
            <Route
              path="cart/:gigId/:gigPackage"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <Cart />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/orders"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <Orders />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/requirements/:orderId"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <CompleteRequirements />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/deliver/:orderId"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <Deliver />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/delivery/:orderId"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <Delivery />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/revision/:orderId"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <Revision />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/revisions/:orderId"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <Revisions />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/review/:orderId"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <LeaveReview />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route
              path="/messages/:orderId"
              element={
                <SessionAuth>
                  <SessionComponent>
                    <Messages />
                  </SessionComponent>
                </SessionAuth>
              }
            />
            <Route path="/terms-and-conditions" element={<TermsOfService />} />
            <Route path="*" element={<FileNotFound />} />
          </Routes>
          <Footer />
        </Router>
      </SuperTokensWrapper>
    </div>
  );
}

export default App;
library.add(fab, fas, far);
