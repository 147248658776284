import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";

export default function Button({ text, onClick, style, enabled = true }) {
  return (
    <MDBBtn
      style={{ backgroundColor: "orange", ...style }}
      onClick={onClick}
      disabled={!enabled}
    >
      {text}
    </MDBBtn>
  );
}
