import React from "react";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import GigCard from "../GigCard/GigCard";

export default function GigGrid({ jobs, gridLayout }) {
  return (
    <div>
      <MDBRow className={gridLayout}>
        {jobs.map((job) => {
          return (
            <MDBCol key={job.id}>
              <GigCard job={job} />
            </MDBCol>
          );
        })}
      </MDBRow>
    </div>
  );
}
