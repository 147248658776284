import { useParams } from "react-router-dom";
import GigGrid from "../SellerProfile/GigGrid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { getApiDomain } from "../App";
import SearchBar from "../SearchBar/SearchBar";
import CenterSpinner from "../Components/CenterSpinner";
import { MDBRow } from "mdb-react-ui-kit";

export default function Search() {
  const [jobs, setJobs] = useState();
  let { query } = useParams();

  useEffect(() => {
    async function getJobs() {
      await axios
        .get(getApiDomain() + "/searchJobs", {
          params: { query: query, limit: 100 },
        })
        .then((response) => {
          if (response.status === 200) {
            setJobs(response.data.jobs);
          }
        })
        .catch((err) => {
          let message = err.response.data.message; // TODO handle any errors
          // setFeatureGridErrorMessage(message);
        });
    }
    getJobs();
  }, [query]);

  return (
    // TODO page results
    <>
      <div className="fill" style={{ width: "100%" }}>
        <div style={{ margin: "2rem" }}>
          <SearchBar />
          <div style={{ marginTop: "2rem" }}>
            <h1 style={{ fontWeight: "bold" }}>Results for: "{query.replace("_", " ")}"</h1>
            {jobs ? (
              <>
                <p>
                  {jobs.length} {jobs.length === 1 ? "service" : "services"} available
                </p>
                <GigGrid
                  jobs={jobs}
                  gridLayout={"row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"}
                />
              </>
            ) : (
              <div className="fill">
                <CenterSpinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
