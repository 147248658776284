import React from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

export default function LinkButton({ fullWidth = false, to, children, style }) {
  return (
    <MDBBtn
      className="me-1"
      style={{
        backgroundColor: "orange",
        textTransform: "none",
        width: fullWidth ? "100%" : "auto",
        marginBottom: ".5rem",
        ...style,
      }}
    >
      <Link to={to} className="text-white text-decoration-none">
        {children}
      </Link>
    </MDBBtn>
  );
}
