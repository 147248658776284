import React from "react";
import { useIsDesktop } from "../Hooks/WindowDimensions";
import LinkButton from "../Components/LinkButton";

export default function BrowseByCategory() {
  return (
    <>
      <h3>Browse by category</h3>
      <div className="row mt-4">
        <div className="col-md-4 mb-4 mb-md-0">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Web Development</h5>
              <p className="card-text">
                Find jobs related to website design, development, and
                management.
              </p>
              <LinkButton to="/search/web_development">BROWSE JOBS</LinkButton>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4 mb-md-0">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Graphic Design</h5>
              <p className="card-text">
                Find jobs related to branding, marketing materials, and visual
                design.
              </p>
              <LinkButton to="/search/graphic_design">BROWSE JOBS</LinkButton>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Content Creation</h5>
              <p className="card-text">
                Find jobs related to writing, editing, and content creation for
                websites, blogs, and social media.
              </p>
              <LinkButton to="/search/content_creation">BROWSE JOBS</LinkButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
