import React, { useState } from "react";
import ProfileSettings from "./ProfileSettings";

export default function Settings() {
  return (
    <div className="fill" style={{ width: "100%" }}>
      <div
        style={{
          margin: "2rem",
          textAlign: "left",
        }}
      >
        <h1 style={{ fontWeight: "bold" }}>Settings</h1>
        <div className="fill">
          <ProfileSettings />
        </div>
      </div>
    </div>
  );
}
