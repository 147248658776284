import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";

export default function AboutCard({ cardStyle, userAccountData }) {
  if (userAccountData === null) {
    return <></>;
  }

  return (
    <MDBCard style={{ ...cardStyle, marginTop: "2rem" }} key={2}>
      <MDBListGroup flush="true">
        <MDBListGroupItem key={1}>
          <MDBCardTitle>Description</MDBCardTitle>
          <MDBCardText>{userAccountData.accountDescription}</MDBCardText>
        </MDBListGroupItem>
        <MDBListGroupItem key={2}>
          <MDBCardTitle>Languages</MDBCardTitle>
          {userAccountData.languages.map((item, index) => {
            return <MDBCardText key={index}>{item}</MDBCardText>;
          })}
        </MDBListGroupItem>
        <MDBListGroupItem key={3}>
          <MDBCardTitle>Linked Accounts</MDBCardTitle>
          {userAccountData.linkedAccounts.map((item, index) => {
            return <MDBCardText key={index}>{item}</MDBCardText>;
          })}
        </MDBListGroupItem>
        <MDBListGroupItem key={4}>
          <MDBCardTitle>Skills</MDBCardTitle>
          {userAccountData.skills.map((item, index) => {
            return <MDBCardText key={index}>{item}</MDBCardText>;
          })}
        </MDBListGroupItem>
      </MDBListGroup>
    </MDBCard>
  );
}
