import React, { useEffect, useState } from "react";
import FillWithMargin from "../Components/FillWithMargin";
import axios from "axios";
import { getApiDomain } from "../App";
import CenterSpinner from "../Components/CenterSpinner";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LinkButton from "../Components/LinkButton";

function Delivery() {
  const { orderId } = useParams();

  const [deliveries, setDeliveries] = useState([]);
  const [deliveryLoading, setDeliveryLoading] = useState(false);

  useEffect(() => {
    async function getDelivery() {
      setDeliveryLoading(true);
      try {
        const response = await axios.get(getApiDomain() + "/getDeliveredOrder", {
          params: {
            orderId: orderId,
          },
        });
        setDeliveries(response.data.deliveries);
        setDeliveryLoading(false);
      } catch (error) {
        console.log("There was an error:", error);
      } finally {
        setDeliveryLoading(false);
      }
    }

    getDelivery();
  }, [orderId]);

  function downloadFiles(delivery) {
    for (let j = 0; j < delivery.files.length; j++) {
      let bytes = new Uint8Array(delivery.files[j].fileBytes.data);
      const file = new File([bytes], delivery.files[j].fileName, {
        type: delivery.files[j].fileType,
      });

      download(file);
    }
  }

  function download(file) {
    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  return (
    <FillWithMargin>
      <h1 style={{ fontWeight: "bold" }}>Your Order Delivery</h1>
      <p>Please see your delivered order below.</p>
      {deliveryLoading ? (
        <CenterSpinner />
      ) : (
        <>
          {deliveries.map((delivery, index) => {
            return (
              <div
                key={index}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                {delivery.revisionId !== null && (
                  <p style={{ fontStyle: "italic" }}>You requested a revision for this order.</p>
                )}
                <p>
                  <span style={{ fontWeight: "bold" }}>Date: </span>
                  {delivery.createdAt}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Description: </span>
                  {delivery.deliveryDescription}
                </p>
                {delivery.files.length > 0 && (
                  <>
                    {delivery.files.map((file, index) => {
                      return (
                        <div key={index}>
                          <p>
                            <span style={{ fontWeight: "bold" }}>File Name: </span>
                            {file.fileName}
                          </p>
                        </div>
                      );
                    })}
                  </>
                )}
                <div style={{ display: "flex" }}>
                  {delivery.files.length > 0 && (
                    <Button onClick={() => downloadFiles(delivery)} text={"Download Files"} />
                  )}
                  {delivery.revisionId !== null && (
                    <LinkButton style={{ marginLeft: ".5rem" }} to={`/revisions/${orderId}`}>
                      VIEW REVISIONS
                    </LinkButton>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </FillWithMargin>
  );
}

export default Delivery;
