import React, { useEffect, useState } from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import axios from "axios";
import { getApiDomain } from "../App";
import FillWithMargin from "../Components/FillWithMargin";
import { useNavigate } from "react-router-dom";

export default function Requirements() {
  const [formFields, setFormFields] = useState({
    requirements: [{ question: "" }],
  });
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [generalError, setGeneralError] = useState("");

  const [centredModal, setCentredModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [removalIndex, setRemovalIndex] = useState(null);

  const toggleShow = () => setCentredModal(!centredModal);
  const navigate = useNavigate();

  const handleFormChange = (event, index) => {
    let data = { ...formFields };
    data.requirements[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const addFields = () => {
    let object = {
      question: "",
    };
    let data = { ...formFields };
    data.requirements.push(object);
    setFormFields(data);
  };

  const removeFields = (index) => {
    let data = { ...formFields };
    data.requirements.splice(index, 1);
    setFormFields(data);
  };

  useEffect(() => {
    async function getFormData() {
      await axios
        .get(getApiDomain() + "/getGigRequirements", {
          params: { param1: "test" },
        })
        .then((response) => {
          if (response.status === 200) {
            setFormFields(response.data);
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          setGeneralError(message);
        });
    }
    getFormData();
  }, []);

  async function saveFormData() {
    setSaveButtonDisabled(true);
    axios
      .post(getApiDomain() + "/saveGigRequirements", formFields)
      .then((response) => {
        if (response.status === 200) {
          setGeneralError("");
          navigate("/post/gallery");
        }
      })
      .catch((error) => {
        let message = error.response.data.generalError;
        setGeneralError(message);
      })
      .finally(() => {
        setSaveButtonDisabled(false);
      });
  }

  return (
    <>
      <FillWithMargin>
        <p>4 of 5</p>
        <h1 style={{ fontWeight: "bold" }}>Add Requirements</h1>
        {generalError !== "" && (
          <div className="mb-4" style={{ color: "red" }}>
            {generalError}
          </div>
        )}
        <div className="mb-4" style={{ paddingRight: "0px" }}>
          Add requirements to help buyers provide you with exactly what you need to start working on
          their order.
        </div>
        <div className="mb-4">
          <MDBTable bordered style={{ marginBottom: "0px" }}>
            <MDBTableHead>
              <tr>
                <th scope="col">Requirement</th>
                <th scope="col"></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {formFields.requirements.map((form, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        name="question"
                        onChange={(event) => handleFormChange(event, index)}
                        style={inputStyle}
                        value={form.question}
                        placeholder="What color pallette would you like me to use on your website?"
                      ></input>
                    </td>
                    <td>
                      {/* <Checkbox /> */}
                      <MDBIcon
                        far
                        icon="minus-square"
                        style={{ color: "orange" }}
                        onClick={() => {
                          setRemovalIndex(index);
                          setCentredModal(!centredModal);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>

        <div className="mb-4">
          <MDBCol style={{ paddingLeft: "0px" }} size="auto">
            <MDBBtn style={{ backgroundColor: "orange" }} onClick={addFields}>
              {/* <MDBIcon far icon="plus-square" /> */}
              Add requirement
            </MDBBtn>
          </MDBCol>
        </div>

        <div className="mb-4" style={{ paddingRight: "0px" }}>
          <MDBBtn
            type="submit"
            block
            style={{ backgroundColor: "orange" }}
            onClick={saveFormData}
            disabled={saveButtonDisabled}
          >
            Save & Continue
          </MDBBtn>
        </div>

        <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Delete requirement</MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={toggleShow}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p>Are you sure you want to delete this requirement?</p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="light" onClick={toggleShow}>
                  Close
                </MDBBtn>
                <MDBBtn
                  onClick={() => {
                    removeFields(removalIndex);
                    toggleShow();
                  }}
                  style={{ backgroundColor: "orange" }}
                >
                  Confirm
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal tabIndex="-1" show={saveModal} setShow={setSaveModal}>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Delete requirement</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={() => setSaveModal(!saveModal)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p>Are you sure you want to delete this requirement?</p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  onClick={() => {
                    setSaveModal(!saveModal);
                  }}
                  style={{ backgroundColor: "orange" }}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </FillWithMargin>
    </>
  );
}

const inputStyle = {
  border: "none",
  backgroundColor: "transparent",
  resize: "none",
  outline: "none",
  width: "100%",
};
