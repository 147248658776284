import React from "react";
import { MDBFooter, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import { useIsDesktop } from "../Hooks/WindowDimensions";

export default function Footer() {
  const isDesktop = useIsDesktop();

  const desktopStyle = {
    display: "flex",
    justifyContent: "center",
    paddingTop: "2rem",
    paddingBottom: "1rem",
  };

  const mobileStyle = {
    paddingTop: "2rem",
    paddingBottom: "1rem",
  };

  return (
    <div style={{ marginTop: "auto" }}>
      <MDBFooter className="text-center" color="white" bgColor="dark">
        <div style={isDesktop ? desktopStyle : mobileStyle}>
          <div style={{ display: "block", marginRight: isDesktop ? "1rem" : "" }}>
            <MDBBtn
              color="light"
              floating
              className="m-1"
              href="https://twitter.com/bitcoinrippin"
              role="button"
            >
              <MDBIcon fab icon="twitter" size="2x" />
            </MDBBtn>
            <p>Follow Us On Twitter</p>
          </div>
          <div style={{ display: "block", marginLeft: isDesktop ? "1rem" : "" }}>
            <MDBBtn
              color="light"
              floating
              className="m-1"
              href="https://t.me/+vGVvP0AWxxVjOTkx"
              role="button"
            >
              <MDBIcon fab icon="telegram" size="2x" />
            </MDBBtn>
            <p>Join Our Telegram Channel</p>
          </div>
        </div>
        <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          © 2023&nbsp;
          <a className="text-white" href="https://mdbootstrap.com/">
            bitcoinerfreelance.com
          </a>
        </div>
      </MDBFooter>
    </div>
  );
}
