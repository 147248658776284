import React, { useEffect, useState, useCallback } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";
import ImageFileUpload from "../Components/ImageFileUpload";
import ImagePreview from "./ImagePreview";
import axios from "axios";
import { getApiDomain } from "../App";
// import useResizedImage from "../Hooks/ResizeImage";
import { resizeImage } from "../Utilities/Images";
import FillWithMargin from "../Components/FillWithMargin";

export default function Gallery() {
  const navigate = useNavigate();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [files, setFiles] = useState([]);
  const [previouslySavedFiles, setPreviouslySavedFiles] = useState([]);
  const [removedFileNames, setRemovedFileNames] = useState([]);

  useEffect(() => {
    // TODO load any previously uploaded images
    async function getFormData() {
      await axios
        .get(getApiDomain() + "/getGigFiles", {
          params: { param1: "test" },
        })
        .then((response) => {
          if (response.status === 200) {
            setPreviouslySavedFiles(response.data.files);
          }
        })
        .catch((error) => {
          // let message = error.response.data.message;
          // setGeneralError(message);
        });
    }
    getFormData();
  }, []);

  async function saveFormData() {
    setGeneralError("");
    setSaveButtonDisabled(true);

    const dataUrls = [];
    for (let i = 0; i < files.length; i++) {
      const canvas = document.createElement("canvas");
      canvas.width = files[i].width;
      canvas.height = files[i].height;
      const context = canvas.getContext("2d");
      context.drawImage(files[i], 0, 0);
      const dataUrl = canvas.toDataURL("image/jpeg");
      dataUrls.push(dataUrl);
    }

    await axios
      .post(getApiDomain() + "/saveGigFiles", {
        dataUrls: dataUrls,
        fileNamesToDelete: removedFileNames,
      })
      .then((response) => {
        if (response.status === 200) {
          setGeneralError("");
          navigate("/gig/" + response.data.gigId);
        }
      })
      .catch((error) => {
        if (error.response.data.generalError) {
          setGeneralError(error.response.data.generalError);
        } else {
          setGeneralError("Something went wrong. Please try again.");
          // TODO email me with error
        }
      })
      .finally(() => {
        setSaveButtonDisabled(false);
      });
  }

  function readFileAsImage(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
        image.src = event.target.result;
      };

      reader.readAsDataURL(file);
    });
  }

  const onFilesChange = useCallback(async (newFileArray) => {
    // resize the images
    let resizedImages = [];
    let totalSize = 0;
    for (let i = 0; i < newFileArray.length; i++) {
      const file = newFileArray[i];
      totalSize += file.size;
      const image = await readFileAsImage(file);
      const resizedImage = await resizeImage(image, 800, 800);
      resizedImages.push(resizedImage);
    }

    if (totalSize > 10000000) {
      //10 million bytes aka 10MB
      setGeneralError("Total size of images cannot exceed 10MB.");
    } else {
      setGeneralError("");
      setFiles(resizedImages);
    }
  }, []);

  return (
    <>
      <FillWithMargin>
        <p>5 of 5</p>
        <h1 style={{ fontWeight: "bold" }}>Add Images</h1>
        {generalError !== "" && (
          <div className="mb-4" style={{ color: "red" }}>
            {generalError}
          </div>
        )}
        {previouslySavedFiles.length > 0 && (
          <>
            <div className="mb-4" style={{ paddingRight: "0px" }}>
              Saved Images
            </div>
            <div className="mb-4">
              <ImagePreview imageList={previouslySavedFiles} onFileRemoved={removeFile} />
            </div>
          </>
        )}
        <div className="mb-4" style={{ paddingRight: "0px" }}>
          Add images that give the buyer an idea of your work.
        </div>
        <div className="mb-4">
          <ImageFileUpload onFilesChange={onFilesChange} setErrorMessage={setGeneralError} />
        </div>
        <div className="mb-4" style={{ paddingRight: "0px" }}>
          <MDBBtn
            type="submit"
            block
            style={{ backgroundColor: "orange" }}
            onClick={saveFormData}
            disabled={saveButtonDisabled}
          >
            POST GIG
          </MDBBtn>
        </div>
      </FillWithMargin>
    </>
  );

  function removeFile(fileName) {
    setRemovedFileNames((prev) => [...prev, fileName]);

    // remove from previouslySavedFiles where fileName === fileName
    setPreviouslySavedFiles((prev) => {
      return prev.filter((file) => file.fileName !== fileName);
    });
  }
}
